var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _c("div", { staticClass: "login-box" }, [_vm._m(0), _c("app-main")], 1),
    _c("div", {
      staticClass: "footer",
      domProps: { innerHTML: _vm._s(_vm.siteData.copyRight) },
    }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logo-box" }, [
      _c("div", [
        _c("a", { staticClass: "logo-title" }, [
          _c("img", {
            staticClass: "logo-img",
            attrs: { src: require("@/assets/logo2.png") },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }