// import parseTime, formatTime and set to filter
export { parseTime, formatTime } from '@/utils';
import { fetchDetail } from '@/api/sys/depart/depart';

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * 通用状态过滤器
 * @param value
 * @returns {*}
 */
export function stateFilter(value) {
  var map = {
    '0': '正常',
    '1': '禁用'
  };
  return map[value];
}
export function sexFilter(value) {
  var map = {
    '1': '男',
    '2': '女'
  };
  return map[value];
}
export function quTypeFilter(value) {
  var map = {
    '1': '单选题',
    '2': '多选题',
    '3': '判断题'
  };
  return map[value];
}
export function paperStateFilter(value) {
  var map = {
    '0': '测评中',
    '1': '待阅卷',
    '2': '已考完',
    '3': '!已弃考'
  };
  return map[value];
}
export function examOpenType(value) {
  var map = {
    '1': '完全公开',
    '2': '指定部门'
  };
  return map[value];
}
export function examStateFilter(value) {
  var map = {
    '0': '进行中',
    '1': '已禁用',
    '2': '待开始',
    '3': '已结束'
  };
  return map[value];
}
export function addTypeStateFilter(value) {
  var map = {
    '1': '加',
    '2': '减',
    '3': '乘',
    '4': '除'
  };
  return map[value];
}