import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

// 主要框架
import Layout from '@/layout';

// 登录框架
import LoginLayout from '@/views/login/components/LoginLayout';
export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: LoginLayout,
  hidden: true,
  children: [{
    path: '/login',
    name: 'Login',
    component: function component() {
      return import('@/views/login/index');
    },
    hidden: true
  }, {
    path: '/register',
    name: 'Register',
    component: function component() {
      return import('@/views/login/register');
    },
    hidden: true
  }]
}, {
  path: '/404',
  component: function component() {
    return import('@/views/error-page/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/error-page/401');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    name: 'Dashboard',
    meta: {
      title: '控制台',
      icon: 'dashboard',
      affix: true
    }
  }, {
    path: 'qu/view/:id',
    component: function component() {
      return import('@/views/qu/qu/view');
    },
    name: 'ViewQu',
    meta: {
      title: '题目详情',
      noCache: true,
      activeMenu: '/manage/qu'
    },
    hidden: true
  }]
}, {
  path: '/profile',
  component: Layout,
  redirect: '/profile/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/profile/index');
    },
    name: 'Profile',
    meta: {
      title: '个人资料',
      icon: 'user',
      noCache: true
    }
  }]
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export var asyncRoutes = [{
  path: '/exam/start/:id',
  component: function component() {
    return import('@/views/paper/exam/exam');
  },
  name: 'StartExam',
  meta: {
    title: '开始测评'
  },
  hidden: true
}, {
  path: '/exam',
  component: Layout,
  redirect: '/exam/repo',
  name: 'Manage',
  meta: {
    title: '检测管理',
    icon: 'example',
    roles: ['sa', 'teacher']
  },
  children: [{
    path: 'repo',
    component: function component() {
      return import('@/views/qu/repo');
    },
    name: 'ListRepo',
    meta: {
      title: '量表管理',
      noCache: true,
      icon: 'repo'
    }
  }, {
    path: 'repo/add',
    component: function component() {
      return import('@/views/qu/repo/form');
    },
    name: 'AddRepo',
    meta: {
      title: '添加量表',
      noCache: true,
      activeMenu: '/exam/repo'
    },
    hidden: true
  }, {
    path: 'repo/update/:id',
    component: function component() {
      return import('@/views/qu/repo/form');
    },
    name: 'UpdateRepo',
    meta: {
      title: '量表详情',
      noCache: true,
      activeMenu: '/exam/repo'
    },
    hidden: true
  }, {
    path: 'rule',
    component: function component() {
      return import('@/views/qu/rule');
    },
    name: 'ListRule',
    meta: {
      title: '因子规则',
      noCache: true,
      icon: 'repo'
    }
  }, {
    path: 'rule/add',
    component: function component() {
      return import('@/views/qu/rule/form');
    },
    name: 'AddRule',
    meta: {
      title: '添加规则',
      noCache: true,
      activeMenu: '/exam/rule'
    },
    hidden: true
  }, {
    path: 'rule/update/:id',
    component: function component() {
      return import('@/views/qu/rule/form');
    },
    name: 'UpdateRule',
    meta: {
      title: '规则详情',
      noCache: true,
      activeMenu: '/exam/rule'
    },
    hidden: true
  }, {
    path: 'qu',
    component: function component() {
      return import('@/views/qu/qu');
    },
    name: 'ListQu',
    meta: {
      title: '试题管理',
      noCache: true,
      icon: 'support'
    }
  }, {
    path: 'qu/add',
    component: function component() {
      return import('@/views/qu/qu/form');
    },
    name: 'AddQu',
    meta: {
      title: '添加试题',
      noCache: true,
      activeMenu: '/exam/qu'
    },
    hidden: true
  }, {
    path: 'qu/update/:id',
    component: function component() {
      return import('@/views/qu/qu/form');
    },
    name: 'UpdateQu',
    meta: {
      title: '修改试题',
      noCache: true,
      activeMenu: '/exam/qu'
    },
    hidden: true
  }, {
    path: 'exam',
    component: function component() {
      return import('@/views/exam/exam');
    },
    name: 'ListExam',
    meta: {
      title: '答卷管理',
      noCache: true,
      icon: 'log'
    }
  }, {
    path: 'exam/add',
    component: function component() {
      return import('@/views/exam/exam/form');
    },
    name: 'AddExam',
    meta: {
      title: '添加答卷',
      noCache: true,
      activeMenu: '/exam/exam'
    },
    hidden: true
  }, {
    path: 'exam/update/:id',
    component: function component() {
      return import('@/views/exam/exam/form');
    },
    name: 'UpdateExam',
    meta: {
      title: '修改答卷',
      noCache: true,
      activeMenu: '/exam/exam'
    },
    hidden: true
  }, {
    path: 'exam/users/:examId',
    component: function component() {
      return import('@/views/user/exam');
    },
    name: 'ListExamUser',
    meta: {
      title: '答卷人员',
      noCache: true,
      activeMenu: '/exam/exam'
    },
    hidden: true
  }, {
    path: 'exam/paper/:examId',
    component: function component() {
      return import('@/views/paper/paper');
    },
    name: 'ListPaper',
    meta: {
      title: '答卷记录',
      noCache: true,
      activeMenu: '/exam/exam'
    },
    hidden: true
  }]
}, {
  path: '/my',
  component: Layout,
  redirect: '/my/exam',
  name: 'Online',
  meta: {
    title: '在线测评',
    icon: 'list',
    roles: ['student', 'sa']
  },
  children: [{
    path: 'exam',
    component: function component() {
      return import('@/views/paper/exam/list');
    },
    name: 'ExamOnline',
    meta: {
      title: '在线测评',
      noCache: true,
      icon: 'guide'
    }
  }, {
    path: 'exam/prepare/:examId',
    component: function component() {
      return import('@/views/paper/exam/preview');
    },
    name: 'PreExam',
    meta: {
      title: '准备测评',
      noCache: true,
      activeMenu: '/my/exam'
    },
    hidden: true
  }, {
    path: 'exam/result/:id',
    component: function component() {
      return import('@/views/paper/exam/result');
    },
    name: 'ShowExam',
    meta: {
      title: '测评结果',
      noCache: true,
      activeMenu: '/online/exam'
    },
    hidden: true
  }, {
    path: 'exam/records',
    component: function component() {
      return import('@/views/user/exam/my');
    },
    name: 'ListMyExam',
    meta: {
      title: '我的成绩',
      noCache: true,
      icon: 'results'
    }
  }, {
    path: 'book/list/:examId',
    component: function component() {
      return import('@/views/user/book');
    },
    name: 'BookList',
    meta: {
      title: '测评错题',
      noCache: true,
      activeMenu: '/my/exam/records'
    },
    hidden: true
  }, {
    path: 'book/training/:examId',
    component: function component() {
      return import('@/views/user/book/train');
    },
    name: 'BookTraining',
    meta: {
      title: '错题训练',
      noCache: true,
      activeMenu: '/my/exam/records'
    },
    hidden: true
  }]
}, {
  path: '/sys',
  component: Layout,
  redirect: '/sys/config',
  name: 'Sys',
  meta: {
    title: '系统设置',
    icon: 'configure',
    roles: ['sa']
  },
  children: [
  // {
  //   path: 'config',
  //   component: () => import('@/views/sys/config'),
  //   name: 'SysConfig',
  //   meta: { title: '系统配置', icon: 'theme' }
  // },

  {
    path: 'depart',
    component: function component() {
      return import('@/views/sys/depart');
    },
    name: 'SysDepart',
    meta: {
      title: '单位管理',
      icon: 'tree'
    }
  },
  //
  // {
  //   path: 'role',
  //   component: () => import('@/views/sys/role'),
  //   name: 'SysRole',
  //   meta: { title: '角色管理', icon: 'role' }
  // },

  {
    path: 'user',
    component: function component() {
      return import('@/views/sys/user');
    },
    name: 'SysUser',
    meta: {
      title: '用户管理',
      icon: 'admin'
    }
  }]
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/dashboard',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;