var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [_c("router-view"), _vm._m(0)], 1)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticStyle: { "text-align": "center" } }, [
        _c("span", [
          _vm._v("Copyright ©2019-2023 北京国奥心理医院有限公司 版权所有"),
        ]),
      ]),
      _c("div", { staticStyle: { "text-align": "center" } }, [
        _c("span", [_vm._v("京ICP备2024048197号-1")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }